import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Icon } from '@shopify/polaris';
import { StoreMajor, ProfileMinor } from '@shopify/polaris-icons';
import './style.css';
import { useSelector } from 'react-redux';

const menuItems = [
    {
        title: 'Stores',
        icon: StoreMajor,
        path: '/stores'
    },
    {
        title: 'Partner',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5c5f62"><path d="M475-160q4 0 8-2t6-4l328-328q12-12 17.5-27t5.5-30q0-16-5.5-30.5T817-607L647-777q-11-12-25.5-17.5T591-800q-15 0-30 5.5T534-777l-11 11 74 75q15 14 22 32t7 38q0 42-28.5 70.5T527-522q-20 0-38.5-7T456-550l-75-74-175 175q-3 3-4.5 6.5T200-435q0 8 6 14.5t14 6.5q4 0 8-2t6-4l136-136 56 56-135 136q-3 3-4.5 6.5T285-350q0 8 6 14t14 6q4 0 8-2t6-4l136-135 56 56-135 136q-3 2-4.5 6t-1.5 8q0 8 6 14t14 6q4 0 7.5-1.5t6.5-4.5l136-135 56 56-136 136q-3 3-4.5 6.5T454-180q0 8 6.5 14t14.5 6Zm-1 80q-37 0-65.5-24.5T375-166q-34-5-57-28t-28-57q-34-5-56.5-28.5T206-336q-38-5-62-33t-24-66q0-20 7.5-38.5T149-506l232-231 131 131q2 3 6 4.5t8 1.5q9 0 15-5.5t6-14.5q0-4-1.5-8t-4.5-6L398-777q-11-12-25.5-17.5T342-800q-15 0-30 5.5T285-777L144-635q-9 9-15 21t-8 24q-2 12 0 24.5t8 23.5l-58 58q-17-23-25-50.5T40-590q2-28 14-54.5T87-692l141-141q24-23 53.5-35t60.5-12q31 0 60.5 12t52.5 35l11 11 11-11q24-23 53.5-35t60.5-12q31 0 60.5 12t52.5 35l169 169q23 23 35 53t12 61q0 31-12 60.5T873-437L545-110q-14 14-32.5 22T474-80Zm-99-560Z"/></svg>',
        path: '/partner'
    },
    {
        title: 'Profile',
        icon: ProfileMinor,
        path: '/profile'
    }
];

const restrictedToRoleZero = ['Stores','Profile'];

const LeftSidebar = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const isActive = (path) => pathname === path;
    const user = useSelector((state) => state.app.user);

    return (
        <div id="kt_aside" className="aside">
            <div className="aside-menu bg-white border rounded-2">
                <div className="menu menu-column menu-rounded menu-sub-indention menu-active-bg">
                    {menuItems.map((item, index) => {
                        if (user.role === 0 && !restrictedToRoleZero.includes(item.title)) {
                            return null;
                        }
                        return (
                            <div key={index} className="menu-item">
                                <NavLink to={item.path} className={isActive(item.path) ? "menu-link active" : "menu-link"}>
                                    <span className="menu-icon">
                                        {typeof item.icon === 'string' ? (
                                            <span dangerouslySetInnerHTML={{ __html: item.icon }} />
                                        ) : (
                                            <Icon source={item.icon} color="base" />
                                        )}
                                    </span>
                                    <span className="menu-title">{item.title}</span>
                                </NavLink>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default LeftSidebar;
