import React, { useEffect, useState } from 'react';
import Leftsidebar from "../component/leftsidebar";
import Header from "../component/header";
import Footer from '../component/footer';
import Foot from "../component/foot";
import { Page, Card } from '@shopify/polaris';
import 'jspdf-autotable';
import axios from 'axios';
import config from '../config';
const apiUrl = config.apiUrl;

const Profile = () => {

    const [stores, setStores] = useState([]);
    const [loginData, setLoginData] = useState(null);

    useEffect(() => {
        const loginUser = localStorage.getItem('userdata');
        if (loginUser) {
            setLoginData(JSON.parse(loginUser));
        }
    }, []);

    const fetchOrders = async () => {
        try {
            const { data } = await axios.get(`${apiUrl}/partner/stores`, {
                headers: { 'Authorization': `Bearer ${localStorage.token}` }
            });

            setStores(data.stores);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchOrders();
        document.title = "Profile";
    }, []);

    const calculateTotalCommission = (data) => {
        return data.reduce((acc, store) => acc + parseFloat(store.total_commission), 0);
    };

    const email = loginData ? loginData.email : "Loading...";
    const partnerId = loginData ? loginData.partner_id : "Loading...";
    const totalCommission = stores.length > 0 ? calculateTotalCommission(stores) : 0;

    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <Leftsidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <Header />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-fluid">
                                    <div className="card bstore store_section_065">
                                        <div className="card-header bstore-bottom">
                                            <div className="col-sm-12 col-md-8 ">
                                                <h3 className="text-dark fw-bold my-1">Partner Orders & Commissions</h3>
                                                <p className='Partner_text'>View orders and commissions linked to your Partner ID.</p>
                                            </div>
                                        </div>

                                        <div className="card-body store_table_body">
                                            <Page fullWidth>
                                                <Card sectioned >

                                                    <div className="profile_section">
                                                        <div className='Store-content-text'>
                                                            <img src="../assets/crImages/mail.png" alt="" />
                                                            <p><strong>Email:</strong> {email}</p>
                                                        </div>
                                                        <div className='Store-content-text'>
                                                            <img src="../assets/crImages/face-id.png" alt="" />
                                                            <p><strong>Partner ID:</strong> {partnerId}</p>
                                                        </div>
                                                        <div className='Store-content-text'>
                                                            <img src="../assets/crImages/payment-processor.png" alt="" />
                                                            <p><strong>Total Commission:</strong> ₹ {totalCommission.toFixed(2)}</p>
                                                        </div>
                                                    </div>


                                                </Card>
                                            </Page>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
                <Foot />
            </div>
        </>
    );
}

export default Profile;
