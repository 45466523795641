import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <div className="app-footer d-flex flex-lg-column " id="kt_app_footer ">
                <div className="app-container  container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3 ">
                    <div className="text-dark order-2 order-md-1">
                        <span className="text-muted fw-semibold me-1">©</span>
                        <span
                            className="text-gray-800"
                        >
                            2024 BuyAfterTry
                        </span>
                    </div>
                    <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                        <li className="menu-item">
                            <Link to="https://webgarh.com/terms-and-conditions.php" className="menu-link px-2" target="_blank">
                                Terms &amp; Conditions
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link to="https://webgarh.com/privacy-policy.php"  className="menu-link px-2" target="_blank">
                                Privacy Policy
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <span className="svg-icon">
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            opacity="0.5"
                            x={13}
                            y={6}
                            width={13}
                            height={2}
                            rx={1}
                            transform="rotate(90 13 6)"
                            fill="currentColor"
                        />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="currentColor"
                        />
                    </svg>
                </span>
            </div>
        </>

    )
}

export default Footer