import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Leftsidebar from "../component/leftsidebar";
import Header from "../component/header";
import Footer from "../component/footer";
import Foot from "../component/foot";
import { Page, Card, DataTable, Grid, Select } from '@shopify/polaris';
import 'jspdf-autotable';
import { ToastContainer } from 'react-toastify';

import * as XLSX from "xlsx/xlsx";
import FileSaver from 'file-saver';

import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Loader from "../Loader/Loader";
import "./style.css"
import { useReactToPrint } from "react-to-print";
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { useSelector } from 'react-redux';
const apiUrl = config.apiUrl;

const Partner = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [excelData, setExcelData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const componentPDF = useRef();
    const navigate = useNavigate();
    const user = useSelector((state) => state.app.user);

    // const formatDate = (isoDate) => {
    //     const date = new Date(isoDate);
    //     const options = { year: 'numeric', month: 'long', day: 'numeric' };
    //     return date.toLocaleDateString('en-US', options);
    // };


    const fetchOrders = async () => {
        try {
            const { data: users } = await axios.get(`${apiUrl}/partner/forAdmin`, {
                headers: { 'Authorization': `Bearer ${localStorage.token}` }
            });
            const formattedOrders = users.map((user, index) => ({
                index: index + 1,
                name: user.name,
                email: user.email,
                partner_id: user.partner_id
            }));

            setData(formattedOrders);
            setFilteredData(formattedOrders);
            setExcelData(formattedOrders);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchOrders();
        document.title = "Stores";
    }, []);

    useEffect(() => {
        const filterData = !searchQuery
            ? data
            : data.filter(store =>
                store.name.toLowerCase().includes(searchQuery.toLowerCase()) || store?.partner_id?.includes(searchQuery)
            );
        setFilteredData(filterData);
    }, [searchQuery, data]);

    const convertToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(excelBlob, 'Orders.xlsx');
    };

    const handlePageChange = useCallback((value) => {
        setPerPage(value);
    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const options = useMemo(() => [
        { label: '10', value: '10' },
        { label: '25', value: '25' },
        { label: '50', value: '50' },
        { label: '100', value: data.length.toString() },
    ], [data.length]);

    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Stores",
    });

    const start = currentPage * perPage;
    const end = start + perPage;
    const paginatedData = filteredData.slice(start, end);


    const rows = useMemo(() => paginatedData.map(user => [
        <div style={{}}><>{user.index}</></div>,
        <>{user.partner_id}</>,
        <>{user.name}</>,
        <>{user.email}</>,
        // <>{formatDate(user.createdAt)}</>,
        <>
            <button className="action_view" onClick={() => navigate(`/stores?id=${user.partner_id}`)}>
                View Stores
            </button>
        </>,
    ]), [paginatedData, navigate]);

    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <Leftsidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper" >
                        <Header />
                        <div className="content d-flex flex-column flex-column-fluid " id="kt_content" >
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-fluid">
                                    <div className="card bstore store_section_065">

                                        <div id="filter_field_1" className="">

                                            <Page fullWidth>
                                                <Grid>
                                                    <Grid.Cell columnSpan={{ xs: 5, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                        <div className="card-title">
                                                            <div className="customSearchBox">
                                                                <div className="input-group position-relative">
                                                                    <button onClick={handleSearchChange} className="search_btn">
                                                                        <i className="las la-search fs-1 search-icon"></i>
                                                                    </button>
                                                                    <input
                                                                        type="text"
                                                                        onChange={handleSearchChange}
                                                                        value={searchQuery}
                                                                        className="form-control bstore mng-hig"
                                                                        aria-label="Text input with dropdown button"
                                                                        placeholder={`Name${user.role === 1 ? ' or Partner Id' : ''}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 3, sm: 1, md: 1, lg: 4, xl: 4 }}>
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 4, sm: 2, md: 2, lg: 4, xl: 4 }}>
                                                        <div className="card-title1" >
                                                            <Grid>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 1, md: 1, lg: 4, xl: 4 }}>
                                                                    <p className="schange">Show</p>
                                                                </Grid.Cell>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 1, md: 1, lg: 4, xl: 4, }} >
                                                                    <Select
                                                                        options={options}
                                                                        onChange={handlePageChange}
                                                                        value={perPage.toString()}
                                                                        className="store_select"
                                                                    />
                                                                </Grid.Cell>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 1, md: 1, lg: 4, xl: 4, }} >
                                                                    <p className="schange1">Entries</p>
                                                                </Grid.Cell>
                                                            </Grid>
                                                        </div>
                                                    </Grid.Cell>
                                                </Grid>
                                            </Page>

                                        </div>

                                        <div className="card-header bstore-bottom py-4 px-6">
                                            <div className="col-sm-12 col-md-8 d-flex align-items-center justify-content-center justify-content-md-start">
                                                <h3 className="text-dark fw-bold my-1">Partners</h3>
                                            </div>

                                            <div className="col-sm-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
                                                <div className="listingCounter d-flex align-items-center">
                                                    <div className="dataTables_info ms-3" id="kt_datatable_example_1_info" role="status" aria-live="polite" >
                                                        <button className="btn btn-lg btn-primary w-100" onClick={() => navigate('/partner/create')}>
                                                            Create Partner
                                                        </button>
                                                    </div>
                                                    <div className="dataTables_info ms-3" id="kt_datatable_example_1_info" role="status" aria-live="polite" >
                                                        Download Partner
                                                    </div>
                                                </div>
                                                <div className="ms-3 iconsexport">

                                                    <div className="exportIcons">
                                                        <ul>
                                                            {/* <li>
                                                                <img onClick={generatePDF} src="assets/crImages/expotIcon1.png" alt="Export" />
                                                            </li> */}
                                                            <li>
                                                                <img onClick={convertToExcel} src="assets/crImages/expotIcon2.png" alt="Export" />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className='dots'>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div ref={componentPDF} className="card-body store_table_body">
                                            <Page fullWidth>
                                                <Card>
                                                    {isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        <DataTable
                                                            columnContentTypes={[]}
                                                            headings={[
                                                                'Sr.No',
                                                                'Partner Id',
                                                                'Name',
                                                                'Email',
                                                                'Action'
                                                            ]}
                                                            rows={rows}
                                                        />
                                                    )}
                                                </Card>

                                            </Page>
                                        </div>
                                        <div className="card-footer">
                                            <p className="me-3">Showing {start} to {end} of {data.length} records</p>
                                            <ReactPaginate
                                                previousLabel={'Previous'}
                                                nextLabel={'Next'}
                                                breakLabel={'...'}
                                                pageCount={Math.ceil(data.length / perPage)}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
            <Foot />
            <ToastContainer />
        </>

    )
}

export default Partner;