import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { ViewMajor, HideMinor } from '@shopify/polaris-icons';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import auth from '../component/Auth';
import config from '../config';
import { useDispatch } from 'react-redux';
import { loginAdmin } from '../Action/appAction';
const apiUrl = config.apiUrl;


// toast.configure();

function Login() {
    const dispatch = useDispatch()
    const [Email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const Submit = async (e) => {
        e.preventDefault();
        var data = {
            email: Email,
            password: password
        };
        try {
            const response = await axios.post(`${apiUrl}/partner/login`, data); // Use axios instance with baseURL
            const token = response.data.token;
            dispatch(loginAdmin(response.data))
            // Get token from response
            localStorage.setItem('token', token); // Set token in local storage
            auth.loginUser({ partner_id: response.data.partner_id, email: response.data.email });
            localStorage.setItem("connect", true)
            localStorage.setItem("loggedin", "You are logged in")
            toast.success('Login successful');
            navigate('/stores');
        } catch (error) {
            const errorMessage = error?.response?.data?.message
            toast.error(errorMessage);
        }

    };

    return (
        <>
            <div className="d-flex flex-column flex-root login_section">
                <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                    <div className="d-flex flex-center flex-column flex-column-fluid p-10">
                        <Link to="/" className="mb-10"></Link>
                        <Link to="/" className="brandName mb-10">
                            <img src="/assets/crImages/bat_logo.png" alt="Logo" />
                        </Link>
                        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                            <form
                                className="form w-100"
                                noValidate="novalidate"
                                id="kt_sign_in_form"
                                action="products.php"
                                method="post"
                            >
                                <div className="text-center mb-10">
                                    <h1 className="text-dark mb-3 fw-semibold">Sign In</h1>
                                </div>
                                <div className="fv-row mb-10">
                                    <label className="form-label fs-6 fw-bold text-dark">
                                        Email
                                    </label>
                                    <input
                                        className="form-control form-control-lg form-control-solid"
                                        type="text"
                                        name="email"
                                        onChange={e => setEmail(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="fv-row mb-10">
                                    <div className="d-flex flex-stack mb-2">
                                        <label className="form-label fw-bold text-dark fs-6 mb-0">
                                            Password
                                        </label>
                                    </div>
                                    <input
                                        className="form-control form-control-lg form-control-solid"
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        onChange={e => setPassword(e.target.value)}
                                        autoComplete="on"
                                    />
                                    {showPassword
                                        ? <div className='eye-icon'>
                                            <ViewMajor onClick={togglePasswordVisibility} />
                                        </div>
                                        : <div className='eye-icon'>
                                            <HideMinor onClick={togglePasswordVisibility} />
                                        </div>
                                    }
                                    {/* <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={togglePasswordVisibility}
                                        >
                                        </button> */}
                                </div>
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        id="kt_sign_in_submit"
                                        className="btn btn-lg btn-primary w-100 mb-5" onClick={Submit}
                                    >
                                        <span className="indicator-label">Continue</span>
                                        <span className="indicator-progress">
                                            Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>

    )
}

export default Login;