import Store from "./pages/store";
import Transferorders from "./pages/orders";
import Login from "./pages/login";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotFound from "./component/NotFound";
import AuthenticatedRoute from "./AuthenticatedRoute"
import UnAuthenticatedRoute from "./UnAthenticatedRoute"
import Profile from "./pages/profile";
import Partner from "./pages/partner";
import CreatePartner from "./pages/createPartner";



const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<UnAuthenticatedRoute Routes />}>
            <Route exact path='/' element={<Login />}></Route>
          </Route>
          <Route element={<AuthenticatedRoute Routes />}>
            <Route exact path='/stores' element={<Store />}></Route>
            <Route exact path='/orders/:store_name' element={<Transferorders />}></Route>
            <Route exact path='/profile' element={<Profile />}></Route>
            <Route exact path='/partner' element={<Partner />}></Route>
            <Route exact path='/partner/create' element={<CreatePartner />}></Route>
            <Route exact path='404' element={<NotFound />}></Route>
            <Route exact path='**' element={<NotFound />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
