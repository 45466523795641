import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, Card } from '@shopify/polaris';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

import Leftsidebar from "../component/leftsidebar";
import Header from "../component/header";
import Footer from "../component/footer";
import Foot from "../component/foot";
import config from '../config';
import "./style.css";

const apiUrl = config.apiUrl;

const CreatePartner = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        partner_id: '',
        role: 0
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleInputUpperCase = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.toUpperCase() });
    };

    const validatePartnerId = (partnerId) => {
        const pattern = /^[A-Z0-9]{8}$/;
        return pattern.test(partnerId);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { partner_id } = formData;

        if (!validatePartnerId(partner_id)) {
            setErrors({ partner_id: 'Partner ID must be exactly 8 characters long.' });
            return;
        }

        try {
            await axios.post(`${apiUrl}/partner/create`, formData, {
                headers: { 'Authorization': `Bearer ${localStorage.token}` }
            });
            toast.success('Partner created successfully');
            setFormData({
                name: '',
                email: '',
                password: '',
                partner_id: '',
                role: 0
            });
            setErrors({});
            navigate('/partner');
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred';
            toast.error(errorMessage);
        }
    };

    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <Leftsidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <Header />
                        <div className="content d-flex flex-column flex-column-fluid create_partner_section" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-fluid">
                                    <div className="card bstore store_section_065">
                                        <div className="card-header bstore-bottom py-4 px-6">
                                            <div className="col-sm-12 col-md-8 d-flex align-items-center justify-content-center justify-content-md-start">
                                                <h3 className="text-dark fw-bold my-1">Create Partner</h3>
                                            </div>
                                        </div>
                                        <Page fullWidth>
                                            <Card>
                                                <div className="form-container" style={{ padding: '20px' }}>
                                                    <form className="form w-100" noValidate="novalidate" onSubmit={handleSubmit} id="kt_sign_in_form">
                                                        <div className="fv-row mb-10">
                                                            <label className="form-label fs-6 fw-bold text-dark">Name</label>
                                                            <input
                                                                className="form-control form-control-lg form-control-solid"
                                                                type="text"
                                                                name="name"
                                                                value={formData.name}
                                                                onChange={handleChange}
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="fv-row mb-10">
                                                            <label className="form-label fs-6 fw-bold text-dark">Email</label>
                                                            <input
                                                                className="form-control form-control-lg form-control-solid"
                                                                type="email"
                                                                name="email"
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                                autoComplete="new-email"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="fv-row mb-10">
                                                            <label className="form-label fw-bold text-dark fs-6 mb-0">Password</label>
                                                            <input
                                                                className="form-control form-control-lg form-control-solid"
                                                                type="password"
                                                                name="password"
                                                                value={formData.password}
                                                                onChange={handleChange}
                                                                autoComplete="new-password"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="fv-row mb-10">
                                                            <label className="form-label fw-bold text-dark fs-6 mb-0">Partner ID</label>
                                                            <input
                                                                className="form-control form-control-lg form-control-solid"
                                                                type="text"
                                                                name="partner_id"
                                                                value={formData.partner_id}
                                                                onChange={handleInputUpperCase}
                                                                autoComplete="on"
                                                                required
                                                            />
                                                            {errors.partner_id && <div className="text-danger">{errors.partner_id}</div>}
                                                        </div>

                                                        <div className="text-center">
                                                            <button
                                                                type="submit"
                                                                id="kt_sign_in_submit"
                                                                className="btn btn-lg btn-primary w-100 mb-5"
                                                            >
                                                                <span className="indicator-label">Create</span>
                                                                <span className="indicator-progress">
                                                                    Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </Card>
                                        </Page>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <Foot />
            <ToastContainer />
        </>
    );
};

export default CreatePartner;
