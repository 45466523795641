function loginUser(user) {
    // debugger;
    setUser(user);
}

function isLogin() {
    return localStorage.getItem('token') ? true : false;
}

function handleLogout() {
    // debugger;
    localStorage.clear();
}

function setUser(user) {
    // debugger;
    localStorage.setItem('userdata', JSON.stringify(user));
    localStorage.setItem('data', JSON.stringify(user));
}

function getAuth() {
    return JSON.parse(localStorage.getItem('data_auth'));
}

module.exports = {
    loginUser: loginUser,
    isLogin: isLogin,
    handleLogout: handleLogout,
    setUser: setUser,
    getAuth: getAuth
};