import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Leftsidebar from "../component/leftsidebar";
import Header from "../component/header";
import Footer from "../component/footer";
import Foot from "../component/foot";
import { Page, Card, DataTable, Grid, Select, TextContainer } from '@shopify/polaris';
import 'jspdf-autotable';
import currencySymbolMap from 'currency-symbol-map';


import * as XLSX from "xlsx/xlsx";
import FileSaver from 'file-saver';

import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Loader from "../Loader/Loader";
import "./style.css"
import { useReactToPrint } from "react-to-print";
import { Link, useParams } from 'react-router-dom';
import config from '../config';
const apiUrl = config.apiUrl;

const Order = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [excelData, setExcelData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const componentPDF = useRef();
    const { store_name } = useParams();
    const [order, setOrder] = useState()

    const fetchOrders = useCallback(async () => {
        try {
            const { data: orders } = await axios.get(`${apiUrl}/partner/order_list`, {
                headers: {
                    'storeurl': store_name,
                    'Authorization': `Bearer ${localStorage.token}`
                }
            });

            setOrder(orders)

            var formattedOrders = orders.orders.map((order, index) => ({
                index: index + 1,
                store_name: order.store_name,
                customer_email: order.customer_email,
                order_id: order.order_id,
                variant_id: order.variant_id,
                order_number: order.order_number,
                status: order.status,
                order_amount: order.current_subtotal_price,
                name: order.line_items.name,
                price: order.line_items.price,
                totalCommission: order.totalCommission,
                currency: order?.line_items?.payment_schedules?.payment_schedules[0]?.currency,
                freeReturnBy: order.line_items.freeReturnBy,
                nextPaymentDate: order.due_at,
                createdAt: order.createdAt,
                dueCommission: order.dueCommission,
                partner_id: order.show_partner ? order.store.partner.partner_id : '--'
            }));

            setData(formattedOrders);
            setFilteredData(formattedOrders);
            setExcelData(formattedOrders);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }, [store_name]);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    useEffect(() => {
        fetchOrders();
        document.title = "Orders";
    }, [fetchOrders]);

    useEffect(() => {
        const filterData = !searchQuery
            ? data
            : data.filter(order =>
                order.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                order.order_number.includes(searchQuery)
            );
        setFilteredData(filterData);
    }, [searchQuery, data]);

    const convertToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(excelBlob, 'Orders.xlsx');
    };

    const handlePageChange = useCallback((value) => {
        setPerPage(value);
    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const options = useMemo(() => [
        { label: '10', value: '10' },
        { label: '25', value: '25' },
        { label: '50', value: '50' },
        { label: '100', value: data.length.toString() },
    ], [data.length]);

    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Orders",
    });

    const start = currentPage * perPage;
    const end = start + perPage;
    const paginatedData = filteredData.slice(start, end);

    const rows = useMemo(() => paginatedData.map(order => [
        <div style={{}}>{order.index}</div>,
        <>#{order.order_number}</>,
        <>{order.partner_id}</>,
        <>{`${currencySymbolMap(order.currency) ? currencySymbolMap(order.currency) : ''} ${order.order_amount}`}</>,
        <>{formatDate(order.createdAt)}</>,
        <>{currencySymbolMap(order.currency)} {order.dueCommission}</>,
        <>{formatDate(order.nextPaymentDate)}</>,
        <>{order.status}</>,
        <>{currencySymbolMap(order.currency)} {order.totalCommission.toFixed(2)}</>
    ]), [paginatedData]);
    const noData = rows.length === 0;

    return (
        <>
            <div className="d-flex flex-column flex-root order_table">
                <div className="page d-flex flex-row flex-column-fluid">
                    <Leftsidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper" >
                        <Header />
                        <div className="content d-flex flex-column flex-column-fluid " id="kt_content" >
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-fluid">
                                    <div className="card border">

                                        <div id="filter_field_1" className="">

                                            <Page fullWidth>
                                                <Grid>
                                                    <Grid.Cell columnSpan={{ xs: 5, sm: 3, md: 3, lg: 4, xl: 4 }}>
                                                        <div className="card-title">
                                                            <div className="customSearchBox">
                                                                <div className="input-group position-relative">
                                                                    <button onClick={handleSearchChange} className="search_btn">
                                                                        <i className="las la-search fs-1 search-icon"></i>
                                                                    </button>
                                                                    <input
                                                                        type="text"
                                                                        onChange={handleSearchChange}
                                                                        value={searchQuery}
                                                                        className="form-control border mng-hig"
                                                                        aria-label="Text input with dropdown button"
                                                                        placeholder="Order Id"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 3, sm: 1, md: 1, lg: 4, xl: 4 }}>
                                                    </Grid.Cell>
                                                    <Grid.Cell columnSpan={{ xs: 4, sm: 2, md: 2, lg: 4, xl: 4 }}>
                                                        <div className="card-title1" >
                                                            <Grid>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 1, md: 1, lg: 4, xl: 4 }}>
                                                                    <p className="schange">Show</p>
                                                                </Grid.Cell>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 1, md: 1, lg: 4, xl: 4, }} >
                                                                    <Select
                                                                        options={options}
                                                                        onChange={handlePageChange}
                                                                        value={perPage.toString()}
                                                                    />
                                                                </Grid.Cell>
                                                                <Grid.Cell columnSpan={{ xs: 4, sm: 1, md: 1, lg: 4, xl: 4, }} >
                                                                    <p className="schange1">Entries</p>
                                                                </Grid.Cell>
                                                            </Grid>
                                                        </div>
                                                    </Grid.Cell>
                                                </Grid>
                                            </Page>
                                        </div>

                                        <div className="card-header border-bottom py-4 px-6 ">
                                            <div className="col-sm-12 col-md-8 d-flex align-items-center justify-content-center justify-content-md-start">
                                                <h3 className="text-dark fw-bold my-1"> <Link to="/stores" ><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" /></svg></Link> {store_name} </h3>
                                            </div>
                                            <div className="col-sm-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
                                                <div className="listingCounter d-flex align-items-center">
                                                    <div className="dataTables_info ms-3" id="kt_datatable_example_1_info" role="status" aria-live="polite" >
                                                        Download Orders
                                                    </div>
                                                </div>
                                                <div className="ms-3 iconsexport">

                                                    <div className="exportIcons">
                                                        <ul>
                                                            {/* <li>
                                                                <img onClick={generatePDF} src="/assets/crImages/expotIcon1.png" alt="Export" />
                                                            </li> */}
                                                            <li>
                                                                <img onClick={convertToExcel} src="/assets/crImages/expotIcon2.png" alt="Export" />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className='dots'>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div ref={componentPDF} className="card-body store_table_body store_table_section">
                                            <Page fullWidth>
                                                <Card>
                                                    {isLoading ? (
                                                        <Loader />
                                                    ) : noData ? (

                                                        <div>
                                                            <TextContainer>
                                                                <p className='no_data'>No Order available</p>
                                                            </TextContainer>
                                                        </div>

                                                    ) : (
                                                        <DataTable
                                                            columnContentTypes={[]}
                                                            headings={[
                                                                'Sr.No',
                                                                'Order ID',
                                                                'Partner ID',
                                                                'Order Amount',
                                                                'Order Date',
                                                                'Pending Commisions',
                                                                'Auto Capture Date',
                                                                'Status',
                                                                'Commission',
                                                            ]}
                                                            rows={rows}
                                                        />
                                                    )}
                                                </Card>

                                            </Page>
                                        </div>

                                        <div className="card-footer">
                                            <p className="me-3">Showing {start} to {end} of {data.length} records</p>
                                            <ReactPaginate
                                                previousLabel={'Previous'}
                                                nextLabel={'Next'}
                                                breakLabel={'...'}
                                                pageCount={Math.ceil(data.length / perPage)}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={10}
                                                onPageChange={handlePageClick}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                    </div>
                                    <div className='comissionCard'>
                                        <h3>Credited Commission: <span>₹ {order?.totalCommission ?? 0}</span></h3>
                                        <h3>Pending Commission: <span>₹ {order?.totalDueCommission ?? 0}</span></h3>
                                        {
                                            order?.partnerTotalCommission > 0 &&  <h3>Partner Commission: <span>₹ {order?.partnerTotalCommission ?? 0}</span></h3>
                                        }
                                        <h3>Total Commission: <span>₹ {(+order?.totalCommission + +order?.totalDueCommission).toFixed(2)}</span></h3>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
            <Foot />
        </>

    )
}

export default Order;