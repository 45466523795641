import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import {
  AppProvider
} from '@shopify/polaris';
import "@shopify/polaris/build/esm/styles.css";
import store from "./store";
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider  store={store}>
    <AppProvider>
      <App />
    </AppProvider>
  </Provider>

);

