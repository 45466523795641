// reducers.js
import { combineReducers } from 'redux';

const initialState = {
  user: {},
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_USER':
      return { ...state, user: action.payload };
    default:
      return state;
  }
};



const rootReducer = combineReducers({
  app: appReducer,
});

export default rootReducer;
