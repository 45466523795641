/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './style.css';
import config from '../config';
import axios from 'axios'
import { useDispatch } from 'react-redux';
import { loginAdmin } from '../Action/appAction';
import { useSelector } from 'react-redux';
const apiUrl = config.apiUrl;
const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [loginData, setLoginData] = useState(null);
    const user = useSelector((state) => state.app.user);
    useEffect(() => {
        axios.get(`${apiUrl}/partner/profile`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        }).then(res => {
            dispatch(loginAdmin(res.data))
            setLoginData(res.data);
        }).catch(error => {
            handleLogout()
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
    };

    const myFunc = (e) => {
        e.preventDefault();
        document.getElementById("myDrop").classList.toggle("show");
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.matches('.btn')) {
                const dropdowns = document.getElementsByClassName("dropdown-menu");
                for (let i = 0; i < dropdowns.length; i++) {
                    const openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
        };

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleShowSideBar = () => {
        const sideBar = document.getElementById("kt_aside");
        if (sideBar.style.display === "flex") {
            sideBar.style.display = "none";
        } else {
            sideBar.style.display = "flex";
            sideBar.style.background = "white";
            sideBar.style.top = "80px";
        }
    };
    return (
        <>
            <div id="kt_header" className="header header-bg">
                <div className="container-fluid ">
                    <div className="header-brand me-5">
                        <div
                            className="d-flex align-items-center d-lg-none ms-n2 me-2"
                            title="Show aside menu"
                        >
                            <div onClick={handleShowSideBar}
                                className="btn btn-icon btn-color-primary btn-active-color-primary w-30px h-30px"
                                id="kt_aside_toggle"
                            >
                                <span className="svg-icon svg-icon-1">
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        {user?.role === 0 && <Link to="/" className="brandLogo">
                            <img src="/assets/crImages/partner_logo.png" alt="Logo" />
                        </Link>}

                        {user?.role === 1 && <Link to="/" className="brandLogo">
                            <img src="/assets/crImages/admin_logo.png" alt="Logo" />
                        </Link>}

                    </div>
                    <div className="topbar d-flex align-items-stretch" >
                        <div className="d-flex align-items-center me-2 me-lg-4 dropdown">
                            <div>
                                <div onClick={myFunc} className="btn btn-borderless  btn-active-primary bg-white bg-opacity-10" >
                                    <span className="svg-icon svg-icon-1 svg-icon-primary  me-0">
                                        {/* <img src="/assets/crImages/businessman.png" className='User_icon' alt="Export" /> */}
                                    </span>
                                    {loginData && loginData.email}
                                    <i className="las la-angle-down"></i>
                                </div>
                                {loginData && <span style={{ padding: '0px 0px 0px 15px' }}><b>{loginData?.partner_id ? 'Partner ID :' : ''}</b> {loginData.partner_id}</span>}
                            </div>
                            <div id="myDrop"
                                className="dropdown-menu menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color py-4 fs-6 w-250px"
                            >
                                <div className="menu-item ">
                                    <a onClick={handleLogout} className="menu-link px-5">
                                        Sign Out
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Header
